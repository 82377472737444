.premiumCont{
    margin-top: 10px;
    display: flex;
    align-items: start;
    gap: 10px;
}
.premiumItem{
    border: 1px solid rgba(147, 147, 147, 0.25);
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 25%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    transition: .5s;
    cursor: pointer;
}.premiumItem:hover{
    transform: scale(1.02);
}
.premiumItem h2{
    font-weight: bold;
}
.premiumItem .price{
    width: 45%;
    height: initial;
}
.premiumItem .price h3{
    font-weight: bold;
    display: flex;
    width: 100%;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 100%;
    border: 1px solid rgba(231, 231, 231, 0.16);
    background-color: rgba(204, 248, 254, 0.69);
}
.premiumTable{
    margin-top: 15px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgba(239, 239, 239, 0.13);
    background-color: rgba(255, 255, 255, 0.65);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
    overflow: hidden;
}
.premiumTable td, th{
    text-align: start;
    padding: 15px;
    color: var(--main-black);
}
.premiumTable tr:nth-child(2n){
    background-color: rgba(218, 215, 254, 0.29);
}
.premiumTable th{
    color: var(--main-primary);
    font-weight: bold;
    font-size: 20px;
}
/*.premiumTable tr{*/
/*    border-bottom: 1px solid var(--light-black);*/
/*}*/
.premiumTable tr:last-of-type{
    border-bottom: none;
}
.premiumTable .xmark{
    color: var(--main-danger);
    border-radius: 30px;
    border: 1px solid var(--main-danger);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
}
.premiumTable .tick{
    color: var(--main-success);
    border-radius: 30px;
    border: 1px solid var(--main-success);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
}
.premiumTable .xmark:hover{
    color: white;
    background-color: var(--main-danger);
    border: 1px solid var(--main-danger);
}
.premiumTable .tick:hover{
    color: white;
    background-color: var(--main-success);
    border: 1px solid var(--main-success);
}
.premiumDiv{
 margin-top: 20px;
}
.premiumBillCard{
    border: 1px solid rgba(147, 147, 147, 0.25);
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 33.333%;
    padding: 15px;
    display: flex;
    gap: 15px;
    transition: .5s;
    cursor: pointer;
}
.premiumBillCard .icon{
    font-weight: bold;
    display: flex;
    width: 20%;
    font-size: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 80px;
    border: 1px solid rgba(231, 231, 231, 0.16);
    background-color: rgb(204, 248, 254);
}
.premiumBillCard p{
    color: var(--light-black);
}
.premiumBillCard span{
    color: rgba(26, 25, 43, 0.63);
    font-weight: bold;
}