.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    align-items: center;
    background-color: white;
}
.logo{
    background-color: rgb(217 217 217 / 60%);
    padding: 0px!important;
    width: 35px;
    border-radius: 10px;
    height: 35px;
    color: black!important;
    display: flex;
    font-size: 25px!important;
    font-weight: bold;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.child{
    margin-top: 10px;
    padding: 10px 15px;
    color: rgb(133 133 133 / 84%);
    transition: 0.5s;
    font-size: 23px;
}
.child:hover{
    color: var(--main-primary);
}
.active{
    color: var(--main-primary);
}