.thirdLight{
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
    transition: .5s;
    font-size: 15px;
}
.thirdLight:hover{
    color: white;
}
.secondaryLight{
    cursor: pointer;
    color: white;
    transition: .5s;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: bold;
}
.secondaryLight:hover{
    background-color: rgba(255, 255, 255, 0.3);
}
.SignUp{
    background-color:  #ffffff;
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 5px;
    color: var(--main-black) !important;
    transition: .5s;
    cursor: pointer;
    text-align: center;
}
.SignUp:hover{
    background-color: rgba(255, 255, 255, 0.75);
}
.Popover{
    display: flex;
    position: absolute;
    flex-direction: column;
    right: 25px;
    top: 57px;
    font-size: 15px;
    gap: 10px;
    background-color: white;
    padding: 10px 0px 0px 0px;
    border-radius: 15px;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 21%);
    z-index: 9999999999999999999;
}
.hr{
    width: 100%;
    border-bottom: .5px solid rgba(171, 171, 171, 0.18);
}
.button_popover{
    color: #6e6e6ec2;
    cursor: pointer;
    padding: 10px;
    width: 200px;
}
.button_popover:hover{
    color: #5d5d5d;
}
.sidebarButt{
    display: flex;
    font-size: 20px;
    background-color: rgba(167, 167, 253, 0.55);
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.messageBox{
    display: flex;
    background-color: white;
    width: 350px;
    border: 1px solid rgb(218, 226, 237);
    box-shadow: rgba(170, 180, 190, 0.3) 0px 4px 20px;
    border-radius: 15px;
    margin-top: 5px;
}