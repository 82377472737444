.table{
    box-shadow: rgba(201, 201, 201, 0.2) 0px 0px 20px 0px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    padding: 10px;
    border-radius: 10px;
}
.table .title{
    text-align: start;
    color: #5d5d5d;
    cursor: pointer;
}
.table .body{
    color: #06152B;
    font-weight: bold;
}
.table .body td{
    padding: 10px 0;
}
.table .body .tr:nth-child(2n){
    background-color: #80808008;
}