.botContainer{
    width: 20%;
}
.botCard{
    border-radius: 15px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
    display: flex;
    padding: 15px;
    border: 1px solid #b9b9b938;
    gap: 15px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.56);
}
.addNewBotCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(90deg, rgba(235, 215, 251, 0.53) 0%, rgba(204, 248, 254, 0.53) 100%);
    background-size: 200% 200%;
    border-radius: 15px;
    font-size: 28px;
    color: var(--main-black);
    transition: 1s;
    height: 100%;
    border: 1px solid rgba(26, 25, 43, 0.01);
    animation: gradient 15s ease infinite;

}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.botCardHeader{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
    gap: 20px;
}
.username{
    color: #ababab;
    font-size: 8px;
    transition: 0.2s;
    cursor: pointer;
    display: block;
}
.username:hover{
    color: var(--main-primary);
}
.heading{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}
.buttons {
    font-size: 20px;
    border-radius: 53px;
    border: 1px solid #02A0FC;
    color: #02A0FC;
    transition: .7s;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.buttons:hover{
    border: 1px solid #02A0FC;
    background-color: #02A0FC;
    color: white;
}
.blueTextModal{
    color: var(--main-primary);
    text-decoration: none;
}

.AddNewBot{
    border: 2px solid grey;
    width: 100%;
    height: 100%;
}
.AddNewBotCard{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 15px;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgb(39 42 42 / 30%) 0px 0px 20px 3px;
}
.addNewBotCard{
    height: 190px;
}
.blueprintBtn{
    padding: 15px;
    background-color: #4339F2;
    color: white;
    width: 26.96939%;
    background-image:
            linear-gradient(rgba(255, 255, 255, 0.22) 2px, transparent 2px),
            linear-gradient(90deg, rgba(255, 255, 255, 0.24) 2px, transparent 2px),
            linear-gradient(rgba(255, 255, 255, 0.12) 1px, transparent 1px),
            linear-gradient(90deg, rgba(255, 255, 255, 0.13) 1px, transparent 1px);
    background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
    border-radius: 10px;
    min-height: 100px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    box-shadow: rgb(235 235 235 / 30%) 0px 0px 5px 1px;
    transition: .5s;
    cursor: pointer;
}
.blueprintBtn:hover{
    transform: scale(1.02);
}
@media screen and (max-width: 576px) {
    .botContainer{
        width: 100%;
    }
    .botCardHeader{
        justify-content: space-evenly;
        gap: 0;
    }
    .AddNewBotCard{
        width: 80%;
    }
    .addNewBotCard{
        height: 190px;
    }
}