.login_input{
    width: 100%;
    padding: 20px 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06)!important;
    border: 2px solid var(--main-background);
    font-size: 20px;
}
.buttons_log{
    display: flex;
    justify-content: space-evenly;
    padding: 15px 0px;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    font-size: 20px;
    transition: 0.5s;
    cursor: pointer;
    font-weight: bold;
}
.auth_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 20px;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    border-radius: 15px;
}
.auth_container .sixDigitInputContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.auth_container .digitInput {
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06)!important;
    border: 2px solid var(--main-background);
    border-radius: 0.5rem;
    padding: 10px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}
.auth_container .dummyInput {
    display: none;
}
.black{
    background-color: var(--main-black);
    border: 1px solid var(--main-black);
    color: white;
}
.black:hover{
    color: var(--main-black);
    background-color: white;
}
.blue{
    background-color: var(--main-primary);
    border: 1px solid var(--main-primary);
    color: white;
}
.blue:hover{
    color: var(--main-primary);
    background-color: white;
}
@media screen and (max-width: 576px) {
    .auth_container{
        width: 100%;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .auth_container{
        width: 100%;
    }
}