.UI{
    position: absolute;
    display: flex;
    border-radius: 10px;
    padding: 6px;
    right: 55px;
    margin-top: 25px;
    gap: 5px;
    z-index: 5;
}
.UI .button{
    color: var(--main-primary);
    background-color: transparent;
    border-radius: 14px;
    width: 60px;
    height: 60px;
    display: flex;
    transition: 0.5s;
    border: 2px solid var(--main-primary);
    align-items: center;
    font-size: 30px;
    justify-content: center;
}
.UI .button:hover{
    color: white;
    background-color: var(--main-primary);
}
.disableButton {
    opacity: 0.4;
}
.disableButton:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-2.5px, 0px) rotate(1deg); }
    30% { transform: translate(2.5px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-2.5px, 1px) rotate(0deg); }
    70% { transform: translate(2.5px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}
@media (max-width: 576px) {
    .UI{
        display: none;
    }
}