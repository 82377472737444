.editCard{
    border: 1px solid rgba(147, 147, 147, 0.25);
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 80%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    transition: .5s;
    cursor: pointer;
}
.editCard .editInput{
    background-color: #dad7fe7a;
    padding: 10px;
    font-weight: bold;
    border: 1px solid #9d9d9d4d;
    border-radius: 5px;
    font-size: 20px;
}
.hr{
    width: 100%;
    border-bottom: .5px solid rgba(171, 171, 171, 0.18);
}
.saveButton{
    background-color: var(--main-primary);
    border: 2px solid var(--main-primary);
    font-weight: bold;
    padding: 10px;
    width: max-content;
    align-self: end;
    font-size: 20px;
    color: white;
    border-radius: 10px;
    transition: .3s linear;
}
.saveButton:hover{
    background-color: white;
    color: var(--main-primary);
}