.input_forms{
    width: 100%;
    font-size: 15px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #b9b9b938;
}
.select_form{
    width: 100%;
    font-size: 15px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #b9b9b938;
    background-color: white;
}
.admin_card{
    padding: 15px;
    background-color: white;
    border: 1px solid #80808014;
    border-radius: 10px;
}