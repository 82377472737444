body{
}
.mainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #1a192b;
}
.innerMainContainer{
    display: flex;
}
.docsMain{
    width: 60%;
    color: var(--main-black);
    background-color: white;
}
.docsMain p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
    margin: 10px 0 10px 0;
}
.docsMain h1{
    margin: 10px 0 15px 0;
}
.docsMain ol{
    padding: 0 15px;
}
.docsMain p:has(code) {
    background-color: #36ceff2e;
    padding: 5px;
    border-radius: 5px;
}
.innerDocsMain{
    padding: 15px 30px;
}
.sidebarContainer{
    width: 20%;
    border-right: 1px solid rgba(128, 128, 128, 0.38);
}
.rightSidebarContainer{
    width: 20%;
    background-color: white;
    border-left: 1px solid rgba(128, 128, 128, 0.38);
}
.sidebarContainer .categories {
    position: fixed;
    padding: 30px 0 0 30px;
}
.sidebarContainer li {
    list-style: none;
    font-weight: bold;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.smlkArticle{
    font-size: 16px;
    font-weight: normal;
    padding: 0px 20px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.heightTrans{
    overflow: hidden;
    display: block;
    transition: height 200ms;
}