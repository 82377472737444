@font-face {
    font-family: Poppins;
    src: url("Poppins-SemiBold.ttf");
    font-weight: bold;
}
@font-face {
    font-family: Poppins;
    src: url("Poppins-Regular.ttf");
    font-weight: normal;
}
@font-face {
    font-family: Poppins;
    src: url("Poppins-Light.ttf");
    font-weight: 100;
}
@font-face {
    font-family: Poppins;
    src: url("Poppins-Black.ttf");
    font-weight: 900;
}@font-face {
    font-family: Poppins;
    src: url("Poppins-Bold.ttf");
    font-weight: 800;
}
@font-face {
    font-family: Montserrat;
    src: url("Montserrat-Bold.ttf");
    font-weight: bold;
}@font-face {
    font-family: Montserrat;
    src: url("Montserrat-SemiBold.ttf");
    font-weight: 700;
}
@font-face {
    font-family: Montserrat;
    src: url("Montserrat-Regular.ttf");
    font-weight: normal;
}
@font-face {
    font-family: Montserrat;
    src: url("Montserrat-Light.ttf");
    font-weight: 100;
}
*{
    padding: 0;
    margin: 0;
}
*:focus {
    outline: none;
}
:root {
    --main-primary: #4339F2;
    --light-primary: #DAD7FE;
    --main-success: #34B53A;
    --main-info: #02A0FC;
    --light-info: #CCF8FE;
    --main-danger: #FF3A29;
    --light-danger: #FFE5D3;
    --main-warning: #FFB200;
    --main-background: rgba(242, 242, 242, 0.56);
    --main-grey: rgba(119, 119, 119, 0.78);
    --main-black: #1a192b;
    --light-black: rgba(37, 36, 56, 0.4);
}
body{
    background-color: var(--main-background);
    margin: 0;
    font-family: "Poppins", serif;
    font-weight: normal;
}
.uk{
    font-family: "Montserrat", serif;
    font-weight: normal;
}
a {
    all: unset;
}
.blur{
    filter: blur(1px);
}
