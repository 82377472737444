.mainContainerProfilePager{
    display: flex;
    gap: 20px;
}
.card{
    display: flex;
}
.tabs{
    display: flex;
    gap: 20px;
    font-size: 17px;
    align-self: center;
    background-color: white;
    border-radius: 10px;
}
.tabs_btn{
    display: flex;
    padding: 5px;
    gap: 5px;
    cursor: pointer;
    border-radius: 5px;
}
.active{
    color: var(--main-primary);
    border-bottom: 2px solid;
}
.profileBotPageCont{
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    border: 1px solid rgb(218 226 237 / 58%);
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}
.leftCont{
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    background-color: white;
    gap: 10px;
    border: 1px solid rgb(218 226 237 / 58%);
    border-radius: 10px;
}
.rightCont{
    width: 100%;
    background-color: white;
    gap: 20px;
    padding: 10px 15px;
    border: 1px solid rgb(218 226 237 / 58%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.rightContAboutFeatures{
    font-size: 1em;
    color: var(--main-grey);
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: start;
}
.botActivatedFileCont{
    display: flex;
    padding: 5px 10px;
    border: 1px solid var(--main-black);
    color: var(--main-black);
    border-radius: 20px;
    font-size: 13px;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.botVisibility{
    display: flex;
    padding: 2px 10px;
    border: 1px solid var(--main-grey);
    border-radius: 20px;
    font-size: 10px;
    color: var(--main-grey);
}
@media screen and (max-width: 576px) {
    .card{
        display: none;
    }
    .mainContainerProfilePager{
        flex-direction: column;
    }
}