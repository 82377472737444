.notAvailableForMobile{
    display: none;
}
.flowArea{
    display: flex;
    flex-direction: row-reverse;
    /*box-shadow: 0 0 4px rgba(0, 0, 0, 0.06);*/
}
.flowArea_Work{
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row-reverse;
    gap: 20px
}
.flowCard{
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06)!important;
}
.flowArea_Navbar{
    width: 350px;
    height: 100%;
    background-color: white;
    padding: 10px 15px;
    border-radius: 15px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06)!important;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: scroll;
}
.flowArea_Navbar::-webkit-scrollbar {
    display: none;
}
.Node_prefab{
    background-color: #FFE5D3;
    padding: 15px 10px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    scroll-snap-align: start;
}
.touchdevice-flow .react-flow__handle-connecting {
    background: #ff6060;
}
.touchdevice-flow .react-flow__handle-valid {
    background: #55dd99;
}
@media (max-width: 576px) {
    .flowArea_Work{
        display: none;
    }
    .notAvailableForMobile{
        box-shadow: 0px 3px 3px -2px rgba(145, 158, 171, 0.2),
                    0px 3px 4px 0px rgba(145, 158, 171, 0.14),
                    0px 1px 8px 0px rgba(145, 158, 171, 0.12);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        padding: 15px;
        border-radius: 15px;
        background-color: white;
        width: 100%;
        height: 100%;
    }
    .notAvailableForMobile div {
        font-size: 15px;
        color: black;
    }
    .notAvailableForMobile svg{
        font-size: 80px;
        color: darkgrey;
    }
}