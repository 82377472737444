.fakeCodeCont{
    width: 45%;
    height: 100vh;
}
.fakeCodePre{
    width: 100%;
    height: 100%;
    display: block;
    font-size: 11px;
    color: white;
    overflow: hidden;
    filter: contrast(0.5);
}
.fakeCodePre::-webkit-scrollbar {
    display: none;
}
@media screen and (max-width: 576px) {
    .fakeCodeCont{
        display: none;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .fakeCodeCont{
        filter: blur(0.8px);
    }
}