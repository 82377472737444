.AdminBotListPanel{
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
}
.AdminBotListPanel .SearchBox{
    width: 50%;
    height: 100%;
    display: flex;
}
.AdminBotListPanel .SearchBox input{
    width: 100%;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #b9b9b938;
}
.AdminBotListPanel .ListType{
    display: flex;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    gap: 5px;
    cursor: pointer;
    border: 1px solid #b9b9b938;

}
.AdminBotListPanel .ListType .ListTypeBlock{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    transition: .5s;
    border-radius: 5px;
    cursor: pointer;
}
.AdminBotListPanel .ListType .ListTypeBlock:hover{
    color: black;
    background-color: #d9d9d9;
}
.AdminBotListPanel .ListType .ListTypeBlock.active{
    color: black;
    background-color: #b7b6b6b8;
}
.AdminBotListPanel .ListType .ListTypeBlock svg{
    width: 20px;
    height: auto;
}
.AdminBotListPanel .AddNewBot{
    background-color: var(--main-black);
    border: 2px solid var(--main-black);
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: .5s;
}
.AdminBotListPanel .PanelButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    color: white;
    border-radius: 10px;
    background-color: var(--main-primary);
    border: 2px solid var(--main-primary);
    font-weight: bold;
    transition: .5s;
}
.AdminBotListPanel .PanelButton:hover{
    background-color: white;
    color: var(--main-primary);
}
.AdminBotListPanel .AddNewBot:hover{
    background-color: white;
    color: var(--main-black);
}