body::-webkit-scrollbar {
    display: none;
}
.container::-webkit-scrollbar {
    display: none;
}
.login_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}
.first_slide_m{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 115px;
}
.first_slide_m .title{
    line-height: 1em;
    font-size: 60px;
    width: 60%;
    display: block;
    text-align: center;
}
.first_slide_m .title span{
    color: var(--main-info);
}
.first_slide_m .containerCardLanding{
    display: flex;
    gap: 20px;
}
.first_slide_m .cardLanding{
    padding: 20px;
    background-color: white;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 3px 3px -2px rgba(145, 158, 171, 0.2),
                0px 3px 4px 0px rgba(145, 158, 171, 0.14),
                0px 1px 8px 0px rgba(145, 158, 171, 0.12);
    cursor: pointer;
}
.first_slide_m .cardLanding:hover{
    border-color: #F4F6F8;
    box-shadow: 0px 3px 5px -1px rgba(145, 158, 171, 0.2),
                0px 5px 8px 0px rgba(145, 158, 171, 0.14),
                0px 1px 14px 0px rgba(145, 158, 171, 0.12);
}
.first_slide_m .cardLanding svg{
    color: var(--main-info);
    font-size: 35px;
}
.first_slide_m .cardLanding p{
    width: 250px;
}
.first_slide_m .cardLanding .title_car_slide_landing{
    font-weight: 800;
    font-size: 27px;
}
.scroll_arr_dowm{
    width: min-content;
    height: 50px;
    display: flex;
    justify-content: center;
}
.scroll{
    position: initial!important;
    width: 40px!important;
    height: 40px!important;;
    padding: 5px;
    border-radius: 15px;
    border: 2px solid black;
    transition: 0.5s;
}
.container{
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    height: 100vh;
}
.fakeCodeBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
}
.fakeCodeBlock p{
    color: #ffffffd1;
    font-size: 15px;
    text-align: center;
    width: 850px;
}
.fakeCodeBlock img{
    width: 80%;
    position: relative;
    z-index:3;
}
.fakeCodeBlock div{
    color: white;
    font-size:60px;
    font-weight: 800;
}
.fakeCodeBlock div b{
    color: transparent;
    font-weight: 800;
}
.third_slide{
    display: flex;
    justify-content: space-around;
    height: 100vh;
    width: 100%;
    color: white;
}
.third_slide .title p {
    color: #a3a3a3;
    font-size: 15px;
}
.third_slide .title h1 {
    font-weight: 800;
    font-size: 46px;
}
.third_slide .title h1 b{
    color: transparent;
    font-weight: 800;
}
.third_slide .title{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 0 15px;
    width: 30%;
}
.third_slide .plusesCont{
    width: 70%;
    background-color: white;
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.third_slide .plusesCont qcont{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}
.third_slide .plusesCont cont{
    display: flex;
    gap: 15px;
    width: 85%;
    padding: 15px;
    flex-wrap: wrap;
    justify-content: start;
}
.third_slide .plusesCont .contItem{
    background-color: white;
    width: 33.333%;
    flex-basis: 32%;
    border-radius: 15px;
    border: 2px solid #a2a2a21c;
    box-shadow: 0px 3px 3px -2px rgba(145, 158, 171, 0.2),
                0px 3px 4px 0px rgba(145, 158, 171, 0.14),
                0px 1px 8px 0px rgba(145, 158, 171, 0.12);
    display: flex;
    flex-direction: column;
    color: black;
    gap: 5px;
    padding: 0 0 14px 0;
    justify-content: space-around;
    cursor: pointer;
}
.third_slide .plusesCont .contItem plusesCont{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}
.third_slide .plusesCont .contItem pluses{
    background-color: #ebf1fc;
    border-radius: 10px;
    padding: 3px 10px;
    color: black;
    font-size: 20px;
}
.third_slide .plusesCont .contItem p{
    color: #4c4c4ca1;
}
.third_slide .plusesCont .contItem .icon{
    width: 80px;
    height: 80px;
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    font-size: 50px;
}
.third_slide .plusesCont .contItem:hover{
    border-color: #F4F6F8;
    box-shadow: 0px 3px 5px -1px rgba(145, 158, 171, 0.2),
                0px 5px 8px 0px rgba(145, 158, 171, 0.14),
                0px 1px 14px 0px rgba(145, 158, 171, 0.12);
}
.fourth_slide{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    z-index: 15;
}
.fourth_slide_section:after{
    background-image: radial-gradient(300px 300px at 78% 412px,rgba(72,58,255,.30) 0,rgba(72,58,255,0) 100%);
    content: "";
    display: block;
    inset: 0;
    position: absolute;
    z-index: 0;
    animation: opacityGradient1 9s ease infinite;
}
.fourth_slide_section:before{
    background-image: radial-gradient(400px 400px at 73% 636px,rgba(255,26,108,.30) 0,rgba(255,26,108,.05) 63%,rgba(255,26,108,0) 100%);
    content: "";
    display: block;
    inset: 0;
    position: absolute;
    z-index: 0;
    animation: opacityGradient2 15s ease infinite;
}
.fifth_slide{
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items:center;
}
.fifth_slide:after{
    background-image: radial-gradient(300px 300px at 28% 577px,rgba(72,58,255,.30) 0,rgba(72,58,255,0) 100%);
    content: "";
    display: block;
    inset: 0;
    position: absolute;
    z-index: 0;
    animation: opacityGradient1 9s ease infinite;
}
.fifth_slide:before{
    background-image: radial-gradient(400px 400px at 18% 518px,rgba(255,26,108,.30) 0,rgba(255,26,108,.05) 63%,rgba(255,26,108,0) 100%);
    content: "";
    display: block;
    inset: 0;
    position: absolute;
    z-index: 0;
    animation: opacityGradient1 15s ease infinite;
}
.fourth_slide .info{
    width: 30%;
}
.fourth_slide .info h1{
    font-size: 60px;
    line-height: 1.15em;
}
.fourth_slide .info p{
    color: #a3a3a3;
}
.fourth_slide .code_img{
    width: 40%;
}
.block{
    height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}
.textGradient b{
    color: transparent;
    font-weight: 800;
}
.textGradient{
    background-image: linear-gradient(90deg,#96ffcd,#58f,#c955ff);
    -webkit-background-clip: text;
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;
}
.fifth_slide .info span{
    background: transparent;
    border: 2px solid #000000;
    padding: 15px 20px;
    display: block;
    width: max-content;
    color: black;
    font-weight: bold;
    border-radius: 10px;
    transition: 1s;
    cursor: pointer;
}
.fifth_slide .info span:hover{
    background: #000000;
    color: white;
}
.fifth_slide .info{
    gap: 10px;
    display: flex;
    flex-direction: column;
}
.signup_pages{

}
@keyframes opacityGradient1 {
    0% {
       opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes opacityGradient2 {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
@media (max-width: 576px) {
    .scroll_arr_dowm{
        display: none;
    }
    .container{
        overflow-x: hidden;
    }
    .first_slide_m{
        gap: 40px;
    }
    .first_slide_m .title {
        font-size: 40px;
        width: 90%;
        line-height: 1.1em;
    }
    .first_slide_m .containerCardLanding{
        gap: 10px;
    }
    .first_slide_m .cardLanding{
        width: 41%;
        padding: 10px;
    }
    .first_slide_m .cardLanding .title_car_slide_landing {
        font-size: 15px;
    }
    .first_slide_m .cardLanding p{
        font-size: 11px;

    }
    .first_slide_m .containerCardLanding {
        flex-direction: row;
        width: 95%;
        justify-content: center;
        flex-wrap: wrap;
        padding: 5px;
    }
    .fakeCodeBlock div{
        line-height: 1.1em;
        width: 96%;
        text-align: center;
    }
    .fakeCodeBlock p{
        width: 96%;
        text-align: center;
        font-size: 14px;
    }
    .fakeCodeBlock img{
        width: 95%;
        position: relative;
        z-index:3;
    }
    .third_slide{
        justify-content: start;
        flex-direction: column;
    }
    .third_slide .title h1 {
        font-size: 50px;
        line-height: 47px;
    }
    .third_slide .title p {
        display: none;
    }
    .third_slide .title{
        width: 100%;
        margin-top: 55px;
        margin-bottom: 10px;
        text-align: center;
        padding: 0 0;
    }
    .third_slide .plusesCont{
        width: 100%;
        height: 100%;
        justify-content: start;
        margin-top: 10px;
    }
    .third_slide .plusesCont .contItem{
        width: 100%;
        flex-basis: 100%;
        font-size: 15px;
    }
    .third_slide .plusesCont cont{
        overflow-x: scroll;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: start;
    }
    .third_slide .plusesCont cont::-webkit-scrollbar {
        display: none;
    }
    .third_slide .plusesCont .contItem .icon{
        font-size: 34px;
    }
    .fourth_slide{
        flex-direction: column;
        margin-top: 50px;
        text-align: center;
        width: 100%;
        gap: 0;
    }
    .fourth_slide .code_img{
        width: 100%;
    }
    .fourth_slide .info{
        width: 100%;
    }
    .fourth_slide .info h1{
        padding: 0 10px;
    }
    .fourth_slide .info p{
        padding: 0 10px;
    }
    .fourth_slide_section:after{
        background-image: radial-gradient(100px 100px at 77% 624px,rgba(72,58,255,.8) 0,rgba(72,58,255,.05) 63%,rgba(72,58,255,0) 100%);
    }
    .fourth_slide_section:before{
        background-image: radial-gradient(150px 150px at 77% 685px,rgba(255,26,108,.6) 0,rgba(255,26,108,.05) 63%,rgba(255,26,108,0) 100%);
    }
    .fifth_slide .fourth_slide{
        flex-direction: column-reverse!important;
    }
    .fifth_slide:after{
    }
    .fifth_slide .fourth_slide{
        gap: 20px!important;
    }
    .fifth_slide .fourth_slide .code_img{
        width: 95%;
    }
    .fifth_slide .fourth_slide .code_img img{
        opacity: 0.8;
        border: none;
        border-radius: 10px;
    }
    .fifth_slide .fourth_slide .info{
        align-items:center;
        gap: 10px;
    }
    .fifth_slide .fourth_slide .info p{
        color: black;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .first_slide_m{
        gap: 20px;
    }
    .first_slide_m .title {
        font-size: 55px;
        width: 95%;
    }
    .first_slide_m .containerCardLanding{
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .first_slide_m .cardLanding{
        width: 41%;
    }
    .fourth_slide .info h1{
        line-height: 1em;
        font-size: 55px;
    }
    .fourth_slide{
        flex-direction: column;
        margin-top: 50px;
        text-align: center;
        width: 100%;
        gap: 5px;
    }
    .fourth_slide .info{
        width: 100%;
        gap: 10px;
        display: flex;
        flex-direction: column;
    }
    .fourth_slide .code_img{
        width: 95%;
    }
    .fourth_slide_section:after{
        background-image: radial-gradient(300px 212px at 78% 563px,rgba(72,58,255,.30) 0,rgba(72,58,255,0) 100%)
    }
    .fakeCodeBlock{
        position: absolute;
        height: 100vh;
        gap: 10px;
    }
    .fakeCodeBlock p{
        width: 95%;
        font-weight: 400;
        color: #dce9fc;
        text-shadow: -1px -1px #ffffff54;
    }
    .fakeCodeBlock .textGradient{
        width: 95%;
        text-align: center;
        line-height: 1.2em;
    }
    .third_slide{
        justify-content: start;
        flex-direction: column;
    }
    .third_slide .title h1 {
        font-size: 50px;
        line-height: 47px;
    }
    .third_slide .title p {
        display: none;
    }
    .third_slide .title{
        width: 100%;
        margin-top: 55px;
        margin-bottom: 10px;
        text-align: center;
        padding: 0 0;
    }
    .third_slide .plusesCont{
        width: 100%;
        height: 100%;
        justify-content: start;
        margin-top: 10px;
    }
    .third_slide .plusesCont .contItem:last-of-type {
        display: none;
    }
    .third_slide .plusesCont .contItem{
        width: 45%;
        flex-basis: 45%;
        font-size: 15px;
        gap: 0;
    }
    .third_slide .plusesCont .contItem p{
        font-size: 15px;
    }
    .third_slide .plusesCont cont{
        overflow-x: scroll;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
    }
    .third_slide .plusesCont cont::-webkit-scrollbar {
        display: none;
    }
    .third_slide .plusesCont .contItem .icon{
        font-size: 34px;
    }
    .third_slide .plusesCont .contItem pluses{
        font-size: 10px;
    }
    .fifth_slide .fourth_slide{
        flex-direction: column-reverse!important;
    }
    .fifth_slide:after{
    }
    .fifth_slide .fourth_slide{
        gap: 20px!important;
    }
    .fifth_slide .fourth_slide .code_img{
        width: 95%;
    }
    .fifth_slide .fourth_slide .code_img img{
        opacity: 0.8;
        border: none;
        border-radius: 10px;
    }
    .fifth_slide .fourth_slide .info{
        align-items:center;
        gap: 10px;
    }
    .fifth_slide .fourth_slide .info span{
        padding: 15px 40px;
    }
    .fifth_slide .fourth_slide .info p{
        width: 80%;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .first_slide_m .title {
        width: 85%;
    }
}
