.card{
    height: 200px;
    border-radius: 15px;
    background-color: var(--main-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    gap: 50px;
    width: 100%;
}
.mainCard {
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 15px;
    font-size: 20px;
    color: var(--main-primary);
    padding: 15px;
}
.mainCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: radial-gradient(#444cf7 2px, #e5e5f7 2px);
    background-size: 40px 40px;
    animation: moveBackground 30s linear infinite;
    z-index: -1;
}
.mainCard p{
    width: 80%;
}
@keyframes moveBackground {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -100px 0;
    }
}