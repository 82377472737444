.node{
    /*height: 16px;*/
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 10px;
    color: rgb(255, 255, 255);
    width: 100px;
    display: block;
}
.api_node{
    background: #62d333;
    border: 1px solid #c7fdb082;
    box-shadow: 0px 0px 11px 0px rgba(98, 211, 51, 0.53);
    transition: 0.5s;
}
.api_node .node_settings{
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 5px;
}
.api_node .node_settings input[type=text]{
    width: 100%;
    border: 2px dashed rgba(98, 211, 51, 0.84);
    background-color: white;
    color: black;
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 8px;
}
.api_node .node_settings .disabled{
    cursor: not-allowed!important;
    pointer-events:none;
    opacity: 50%;
}
.api_node .node_settings select{
    width: 100%;
    border: 2px dashed rgba(98, 211, 51, 0.84);
    background-color: white;
    color: black;
    padding: 2px;
    border-radius: 5px;
    font-size: 12px;
}
.api_node .node_settings select option{
    font-size: 20px;
}
.api_node .node_settings .checkbox{
    display: flex;
    align-content: center;
    justify-content: right;
}
.api_node .close_butt{
    border: #dbffcb 1.5px solid;
    position: absolute;
    top: -8px;
    height: 15px;
    justify-content: center;
    width: 15px;
    right: -6px;
    display: flex;
    color: aliceblue;
    background-color: rgba(98, 211, 51, 0.53);
    border-radius: 11px;
    font-size: 8px;
    transition: 0.5s;
    align-items: center;
    cursor: default;
}
.api_node .node_settings .minus {
    display: flex;
    align-items: center;
    background: #ffffff38;
    width: 49px;
    justify-content: center;
    height: auto;
    border-radius: 3px;
    border: 1px dashed #ffffff52;
    cursor: pointer;
}
.api_node .node_settings .plus {
    display: flex;
    align-items: center;
    background: #ffffff38;
    width: 100%;
    justify-content: center;
    padding: 5px 0px;
    border-radius: 3px;
    border: 1px dashed #ffffff52;
    cursor: pointer;
}
.api_node .close_butt:hover {
    transform: scale(1.2);
}
.api_node .settings{
    float: right;
    background-color: #ffffff8a;
    height: 14px;
    width: 15px;
    border-radius: 5px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
.api_node .successApi{
    border-radius: 0;
    left: 20px;
    width: 23.5px;
    background-color: #147DF5;
    border: 1px solid #63a7ff;
}
.api_node .successApi:before{
    color: white;
    content: "Success";
    font-size: 5px;
    text-align: center;
    margin-left: 1px;
    margin-bottom: 29px;
    position: absolute;
    font-weight: bold;
}
.api_node .errorApi{
    border-radius: 0;
    width: 23.5px;
    background-color: #ff4343;
    border: 1px solid #ff8787f2;
}
.api_node .errorApi:before{
    color: white;
    content: "Error";
    font-size: 5px;
    text-align: center;
    margin-left: 1px;
    margin-bottom: 29px;
    position: absolute;
    font-weight: bold;
}
.api_json_btn{
    background-color: #62d3333d;
    border: none;
    height: 25px;
    width: 25px;
    box-shadow: 0 0 4px 0 #62d33321;
    color: #163808;
    border-radius: 3px;
    font-size: 12px;
    transform: scale(0.8);
    transition: 1s;
}
.api_json_btn:hover{
    transform: scale(1);
}
.poll_node .question{
    width: 100%;
    border: 2px dashed #d688fa;
    background-color: white;
    color: black;
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 10px;
}
.poll_node .plus {
    display: flex;
    align-items: center;
    background: #ffffff38;
    width: 100%;
    justify-content: center;
    padding: 5px 0px;
    border-radius: 3px;
    border: 1px dashed #ffffff52;
    cursor: pointer;
}
.poll_node .minus {
    display: flex;
    align-items: center;
    background: #ffffff38;
    width: 29px;
    justify-content: center;
    height: auto;
    border-radius: 3px;
    border: 1px dashed #ffffff52;
    cursor: pointer;
}
.poll_node .icon_settings{
    border: 1px solid #ffffff14;
    cursor: pointer;
    width: 15px;
    height: 15px;
    background: #ffffff1c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: 0.5s;
}
.poll_node .icon_settings:hover{
    border: 1px solid rgba(255, 255, 255, 0.11);
    background: rgba(255, 255, 255, 0.28);
}
.poll_node .icon_settings.active{
    color: black;
    border: 1px solid #ffffff96;
    background: #ffffffb8;
}
.poll_node .icon_settings.active:hover{
    border: 1px solid rgba(255, 255, 255, 0.53);
    background: rgba(255, 255, 255, 0.6);
}
.command_node{
    background: #FF8700;
    border: 1px solid #ffc585;
    box-shadow: 0 0 11px 0 #ffc585;
}
.command_node .icon{
    font-size: 10px;
}
.command_node .close_butt{
    border: #fff0e4 1.5px solid;
    position: absolute;
    top: -6px;
    right: -5px;
    padding: 3.5px 6px;
    color: #fff0e4;
    background-color: rgba(255, 161, 64, 0.51);
    border-radius: 11px;
    font-size: 8px;
    transition: 0.5s;
    cursor: default;
}
.command_field {
    margin-top: 5px;
    display: flex;
}
.command_field input{
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.35);
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 5px;
    font-size: 10px;
    padding: 5px;
    color: white;
}
.command_node .close_butt:hover {
    transform: scale(1.2);
}
.message_node{
    background: #BE0AFF;
    border: 1px solid #d688fa;
    box-shadow: 0px 0px 11px 0px #d688fa;
    width: 300px;
}
.message_node_by_id{
    background: #fc4afa;
    border: 1px solid #ffa3fe;
    box-shadow: 0px 0px 11px 0px #ffa3fe;
    width: 300px;
}
.openAIChat{
    background: #681efd;
    border: 1px solid #7477ff;
    box-shadow: 0px 0px 11px 0px #7477ff;
    width: 300px;
}
.openAIChat .node_settings{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}
.openAIChat .close_butt{
    border: #dce9fc 1.5px solid;
    position: absolute;
    top: -6px;
    right: -5px;
    padding: 3.5px 6px;
    color: #dce9fc;
    background-color: rgba(116, 119, 255, 0.51);
    border-radius: 11px;
    font-size: 8px;
    transition: 0.5s;
    cursor: default;
}
.openAIChat .close_butt:hover {
    transform: scale(1.2);
}
.openAIChat .message_txtarea{
    display: flex;
}
.openAIChat .message_txtarea textarea{
    width: 100%;
    border: 2px dashed #7477ff;
    background-color: white;
    color: black;
    padding: 5px 5px;
    resize: none;
    border-radius: 5px;
    height: 75px;
    font-size: 10px;
}
.message_node .title{
    font-size: 15px;
    font-weight: bold;
}
.message_node .close_butt{
    border: #f3e1fc 1.5px solid;
    position: absolute;
    top: -6px;
    right: -5px;
    padding: 3.5px 6px;
    color: #f3e1fc;
    background-color: rgba(214, 136, 250, 0.51);
    border-radius: 11px;
    font-size: 8px;
    transition: 0.5s;
    cursor: default;
}
.message_node .close_butt:hover {
    transform: scale(1.2);
}
.message_node .message_txtarea{
    width: 100%;
    margin: 10px 0 0 0;
    display: flex;
}
.message_node .message_input{
    width: 100%;
    margin: 10px 0 0 0;
    display: flex;
}
.message_node_by_id .message_input input {
    width: 100%;
    border: 2px dashed #ffa3fe;
    background-color: white;
    color: black;
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 10px;
}
.message_node .message_txtarea textarea{
    width: 100%;
    border: 2px dashed #d688fa;
    background-color: white;
    color: black;
    padding: 5px 5px;
    resize: none;
    border-radius: 5px;
    height: 75px;
    font-size: 10px;
}
.message_node_by_id .message_txtarea textarea {
    border: 2px dashed #ffa3fe;
}
.message_node .buttons_node, .posts_node .buttons_node, .posts_import_node .buttons_node{
    border-radius: 0;
    left: 20px;
    width: 23.5px;
    background-color: #147DF5;
    border: 1px solid #63a7ff;
}
.message_node .buttons_node:before,.posts_node .buttons_node:before, .posts_import_node .buttons_node:before{
    color: white;
    content: "buttons";
    font-size: 5px;
    text-align: center;
    margin-left: 1px;
    margin-bottom: 29px;
    position: absolute;
    font-weight: bold;
}
.url_node{
    background: #00b8ff;
    border: 1px solid #00b8ff;
    box-shadow: 0px 0px 11px 0px #00b8ff;
    transition: 0.5s;
}
.url_node .close_butt{
    border: #dce9fc 1.5px solid;
    position: absolute;
    top: -6px;
    right: -5px;
    padding: 3.5px 6px;
    color: #dce9fc;
    background-color: rgba(0, 184, 255, 0.51);
    border-radius: 11px;
    font-size: 8px;
    transition: 0.5s;
    cursor: default;
}
.url_node .close_butt:hover {
    transform: scale(1.2);
}
.url_node label{
    font-size: 7px;
}
.url_node .button_name{
    width: 100%;
    border: 2px dashed #00b8ff;
    padding: 5px 0px;
    border-radius: 5px;
}
.url_node .button_name{
    width: 100%;
    border: 2px dashed #00b8ff;
    padding: 5px 0px;
    border-radius: 5px;
}
.button_node{
    background: #147DF5;
    border: 1px solid #63a7ff;
    box-shadow: 0px 0px 11px 0px #63a7ff;
    transition: 0.5s;
}
.button_node .close_butt{
    border: #dce9fc 1.5px solid;
    position: absolute;
    top: -6px;
    right: -5px;
    padding: 3.5px 6px;
    color: #dce9fc;
    background-color: rgba(99, 167, 255, 0.51);
    border-radius: 11px;
    font-size: 8px;
    transition: 0.5s;
    cursor: default;
}
.button_node .close_butt:hover {
    transform: scale(1.2);
}
.button_node div{
    transition: 0.2s;
}
.button_node .settings{
    float: right;
    background-color: #ffffff8a;
    padding: 2px 3px;
    border-radius: 15px;
}
.button_node .button_name{
    width: 100%;
    border: 2px dashed #63a7ff;
    padding: 5px 0px;
    border-radius: 5px;
}
.button_node .toggle{
    width: 10px;
    font-size: 5px;
}
.posts_import_node{
    width: 250px;
    background: #f64770;
    border: 1px solid rgba(250, 172, 190, 0.8);
    box-shadow: 0px 0px 11px 0px rgba(248, 96, 133, 0.79);
    transition: 0.5s;
}
.posts_import_node .close_butt{
    border: #ffeff3 1.5px solid;
    position: absolute;
    top: -6px;
    right: -5px;
    padding: 3.5px 6px;
    color: #ffeff3;
    background-color: rgba(255, 139, 167, 0.51);
    border-radius: 11px;
    font-size: 8px;
    transition: 0.5s;
    cursor: default;
}
.posts_import_node .close_butt:hover {
    transform: scale(1.2);
}
.posts_import_node .post_settings .img_import{
    height: 50px;
}
.posts_import_node .post_settings .img_import{
    margin-top: 10px;
    border:  rgba(255, 255, 255, 0.51) 2px dashed;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
}
.posts_import_node .file_preview{
    background-color: rgba(255, 255, 255, 0.29);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border-radius: 5px;
}
.posts_import_node .file_preview .filename{
    font-size: 9px;
}
.posts_import_node .file_preview .size{
    font-size: 5px;
}
.posts_import_node .mess #file_description {
    font-size: 10px;
}
.posts_node{
    background: #f15bb5;
    border: 1px solid #f6a3d6;
    box-shadow: 0px 0px 11px 0px #f6a3d6;
    transition: 0.5s;
}
.posts_node .close_butt{
    border: #ffdef2 1.5px solid;
    position: absolute;
    top: -6px;
    right: -5px;
    padding: 3.5px 6px;
    color: #ffdef2;
    background-color: rgba(246, 163, 214, 0.51);
    border-radius: 11px;
    font-size: 8px;
    transition: 0.5s;
    cursor: default;
}
.posts_node .close_butt:hover {
    transform: scale(1.2);
}
.posts_node .settings{
    float: right;
    background-color: #ffffff8a;
    padding: 2px 3px;
    border-radius: 15px;
}
.posts_node .post_settings{
    width: 100%;
    margin-top: 10px;
}
.posts_node .post_settings .img_import{
    height: 50px;
}
.posts_node .post_settings .img_import{
    border: #f6a3d6 2px dashed;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
}
.post_img_preview img{
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.post_img_preview .delete_img{
    position: absolute;
    right: 23px;
    top: 43px;
    background-color: #ffffff94;
    padding: 3px 5px;
    color: black;
    border-radius: 18px;
    transition: 1s;
    cursor: pointer;
}
.post_img_preview .delete_img:hover{
   transform: scale(1.2);
}
.posts_node .post_description{
    margin-top: 10px;
}
.posts_node .post_description .title{
    width: 100%;
    border: 2px dashed rgba(246, 163, 214, 0.57);
    background-color: white;
    color: black;
    padding: 5px 0px;
    text-align: center;
    border-radius: 5px;
}

.posts_node .post_description .description{
    margin-top: 10px;
    width: 100%;
    border: 2px dashed rgba(246, 163, 214, 0.57);
    background-color: white;
    color: black;
    padding: 5px 0px;
    resize: none;
    text-align: center;
    border-radius: 5px;
    height: 100px;
}
.chips_row{
    margin-top: 15px;
    display: flex;
    gap: 5px;
}
.node .chip{
    background-color: rgba(255, 255, 255, 0.37);
    padding: 6px 6px;
    border-radius: 10px;
    font-size: 7px;
    border: 1px solid #ffffff29;
    width: 25%;
    transition: 0.5s;
    text-align: center;
}
.node .chip:hover{
    background-color: rgba(255, 255, 255, 0.45);
}
.file_node{
    width: 250px;
}
.file_node .file_import{
    border: rgba(255, 255, 255, 0.56) 2px dashed;
    background-color: rgba(255, 255, 255, 0.16);
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    height: 50px;
}
.file_node .file_settings{
    width: 100%;
    margin-top: 10px;
}
.file_node .file_description{
    margin-top: 10px;
}
.file_node .file_description label{
    font-size: 7px;
}
.file_node .file_settings .mess{
    width: 100%;
    display: flex;
}
.file_node .file_settings textarea{
    width: 100%;
    padding: 5px 5px;
    border: 2px dashed #d688fa;
    background-color: white;
    color: black;
    resize: none;
    border-radius: 5px;
    height: 40px;
}
.file_node .file_preview{
    background-color: rgba(255, 255, 255, 0.29);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border-radius: 5px;
}
.file_node .file_preview .filename{
    font-size: 9px;
}
.file_node .file_preview .size{
    font-size: 5px;
}
.file_node .mess #file_description{
    font-size: 10px;
}
.rss_node{
    background: #FF8700;
    border: 1px solid #ffc585;
    box-shadow: 0 0 11px 0 #ffc585;
    color: white;
}
.rss_node .close_butt{
    border: #fff0e4 1.5px solid;
    position: absolute;
    top: -6px;
    right: -5px;
    padding: 3.5px 6px;
    color: #fff0e4;
    background-color: rgba(255, 161, 64, 0.51);
    border-radius: 11px;
    font-size: 8px;
    transition: 0.5s;
    cursor: default;
}
.rss_node .close_butt:hover {
    transform: scale(1.2);
}
.rss_node input{
    width: 100%;
    border: 2px dashed #ff9219ab;
    background-color: white;
    border-radius: 5px;
    font-size: 10px;
    padding: 5px;
    color: var(--main-black);
}
.channel_settings_node{
    background: rgba(236, 255, 213, 0.71);
    border: 1px solid rgb(210, 255, 155);
    box-shadow: 0 0 11px 0 rgba(236, 255, 213, 0.71);
    color: var(--main-black);
}
.channel_settings_node .input{
    background-color: white;
    border: 2px dashed rgb(210, 255, 155);
    border-radius: 10px;
    width: 100%;
    padding: 5px;
}
.command_node .close_butt:hover {
    transform: scale(1.2);
}