.sidePanelContainer{
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 25px;
}
.InfoBlock{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06)!important;
    padding: 15px;
    border-radius: 15px!important;
    height: 31%;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.blueTxt{
    color: rgb(133 133 133 / 84%);
    transition: 0.5s;
    cursor: pointer;
}
.blueTxt:hover{
    color: var(--main-primary);
}
.EditButton{
    background: linear-gradient(90deg, #CCF8FE 0%, #E2FBD7 33%, #FFE5D3 100%);
    background-size: 400% 400%;
    width: 100%;
    text-align: center;
    padding: 20px 10px;
    border-radius: 15px;
    font-size: 18px;
    color: #1a192b;
    transition: 1s;
}
.EditButton:hover{
    animation: gradient 15s ease infinite;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.Terminal{
    border: 1px solid #1a192b;
    background-color: #1a192b;
    overflow-y: scroll;
    color: white;
    padding: 10px;
    font-size: 12px;
    font-family: "system-ui";
    scrollbar-width: none;
    border-radius: 15px;
    display: flex;
    flex-direction: column-reverse;
}
.Terminal::-webkit-scrollbar {
    display: none;
}
.Terminal p{
    margin-top: 5px;
}
.UpdateTerminal{
    position: absolute;
    background-color: rgba(255, 255, 255, 0.52);
    padding: 10px 11px;
    border-radius: 15px;
    color: #1a192b;
    transition: .5s;
}
.UpdateTerminal:hover{
    background-color: white;
}
.FlowPreview{
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 5px;
    border-radius: 15px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06)!important;
}
.ChartsCard {
    width: 100%;
    height: 36vh;
    border-radius: 15px;
    display: flex;
    gap: 20px;
}
.LineChart{
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06)!important;
    height: 100%;
    border-radius: 15px;
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.EmptyData{
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06)!important;
    height: 100%;
    border-radius: 15px;
    padding: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b5b5b5;
}
.collaborativeCard{
    border-radius: 15px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06)!important;
    background-color: white;
    width: 100%;
    height: 10%;
    padding: 10px 0;
}
.colabLink{
    background-color: #dad7fe7a;
    padding: 5px;
    font-weight: bold;
    border: 1px solid #9d9d9d4d;
    border-radius: 5px;
    font-size: 20px;
    width: 100%;
    cursor: pointer;
}
.colabBtns{
    background-color: #dad7fe7a;
    font-weight: bold;
    border: 1px solid #9d9d9d4d;
    border-radius: 5px;
    font-size: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.userListColab{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: 500px;
    overflow: auto;
}
.userListColab::-webkit-scrollbar {
    display: none;
}
.socialNetworkShare{
    display: flex;
    width: 20px;
    height: 20px;
    padding: 5px;
    border: 1px solid #DAE2ED;
    border-radius: 10px;
    color: var(--main-black);
    transition: .2s;
}
.socialNetworkShare:hover{
    color: var(--main-primary)
}
.activeFile{
    display: flex;
    border: 1px solid var(--main-success);
    background-color: white;
    color: var(--main-success);
    padding: 0 5px;
    border-radius: 20px;
    transition: .5s;
    font-weight: bold;
    cursor: pointer;
    z-index: 99;
    align-items: center;
}
.activeFile:hover{
    background-color: var(--main-success);
    color: white;
}
.activeBtn{
    cursor: pointer;
    border: 1px solid var(--main-black);
    width: 60%;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 15px;
    transition: .5s;
    font-weight: bold;
}
.activeBtn:hover{
    background-color: var(--main-black);
    color: white;
}
.editBtn{
    background-color: var(--main-primary);
    border: 1px solid var(--main-primary);
    border-radius: 30px;
    color: white;
    font-weight: bold;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
    z-index: 99;
    cursor: pointer;
}
.editBtn:hover{
    background-color: white;
    color: var(--main-primary);
}
.panelabs{
    position: absolute;
    margin-left: 17px;
    margin-top: 17px;
    display: flex;
    gap: 10px;
}
@media (max-width: 576px) {
    .sidePanelContainer{
        width: 100%;
    }
    .InfoBlock{
        gap: 20px;
    }
}