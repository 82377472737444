.inputForm{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.inputForm input{
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 1px 6px rgb(229 225 225 / 69%);
    border: 2px solid rgb(149 149 149 / 15%);
    font-size: 20px;
}
.inputForm label{
    align-self: start;
    color: var(--main-grey);
}
.buttons_log{
    display: flex;
    justify-content: space-evenly;
    padding: 15px 0px;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    font-size: 20px;
    transition: 0.5s;
    cursor: pointer;
    font-weight: bold;
}
.black{
    background-color: var(--main-black);
    border: 1px solid var(--main-black);
    color: white;
}
.black:hover{
    color: var(--main-black);
    background-color: white;
}
.blue{
    background-color: var(--main-primary);
    border: 1px solid var(--main-primary);
    color: white;
}
.blue:hover{
    color: var(--main-primary);
    background-color: white;
}