.SnackbarContainer{
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    z-index: 999;
}
.Snackbar{
    color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
    width: 50%;
    height: 130px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.success{
    background-color: rgba(52, 181, 58, 0.87);
}
.info{
    background-color: rgb(2 160 252 / 87%);
}
.error{
    background-color: rgba(255, 58, 41, 0.87);
}
.closeButton{
    background-color: white;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px 25px;
    font-weight: bold;
    transition: .5s;
    cursor: pointer;
}
.closeButton:hover{
    color: white!important;
    background-color: rgba(255, 255, 255, 0.17);
}
.success .closeButton{
    color: rgba(52, 181, 58, 0.87);
}
.info .closeButton{
    color: rgb(2 160 252 / 87%);
}
.error .closeButton{
    color: rgba(255, 58, 41, 0.87);
}
