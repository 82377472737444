.Loading{
    position: absolute;
    z-index: 88;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--main-info);
}
.Loading svg{
    color: white;
    width: 100px;
    height: 100px;
}